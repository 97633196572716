import { sqlDate } from "@/plugins/variables"
import store from "."

export interface IUtilisateur {
  ut_id: number
  ut_nom: string
  ut_prenom: string
  ut_date_entree: string | null
  ut_date_sortie: string | null
  autorisation?: string[]
}

export class TransfertDA {
  private st_id: string
  private stda_id: string
  private stda_codeDepart: string
  private stda_codeArriver: string | null
  private stda_dateHeureDebut: Date | string
  private stda_dateHeureArriver: Date | string | null
  constructor(DA: TransfertDA) {
    this.st_id = DA.st_id
    this.stda_id = DA.stda_id
    this.stda_codeDepart = DA.stda_codeDepart
    this.stda_codeArriver = DA.stda_codeArriver
    this.stda_dateHeureDebut = DA.stda_dateHeureDebut
    this.stda_dateHeureArriver = DA.stda_dateHeureArriver
  }
  getId() {
    return this.st_id
  }
  getDAId() {
    return this.stda_id
  }
  getCodeDepart() {
    return this.stda_codeDepart
  }
  getCodeArriver() {
    return this.stda_codeArriver
  }
  getDateHeureDebut() {
    return this.stda_dateHeureDebut
  }
  getDateHeureArriver() {
    return this.stda_dateHeureArriver
  }
  setCodeDepart(stda_codeDepart: string) {
    this.stda_codeDepart = stda_codeDepart
  }
  setCodeArriver(stda_codeArriver: string) {
    this.stda_codeArriver = stda_codeArriver
  }
}

export class Transfert {
  private st_id: string
  private sc_code: string
  private sl_code: string | null
  private st_numCharge: string | null
  private ut_id: number
  private sm_id: number | null
  private st_observation: string
  private AEnvoyerALaBD: boolean
  private DA: TransfertDA[]

  constructor(unTransfert: Transfert) {
    this.st_id = unTransfert.st_id
    this.sc_code = unTransfert.sc_code
    this.sl_code = unTransfert.sl_code
    this.st_numCharge = unTransfert.st_numCharge
    this.ut_id = unTransfert.ut_id
    this.sm_id = unTransfert.sm_id
    this.st_observation = unTransfert.st_observation
    this.AEnvoyerALaBD = unTransfert.AEnvoyerALaBD == undefined ? false : unTransfert.AEnvoyerALaBD
    this.DA = unTransfert.DA
  }

  getId() {
    return this.st_id
  }
  getCodeCuvier() {
    return this.sc_code
  }
  getCodeLaitier() {
    return this.sl_code
  }
  getNumCharge() {
    return this.st_numCharge
  }
  getUtilisateur() {
    return this.ut_id
  }
  getMachine() {
    return this.sm_id
  }
  getObservation() {
    return this.st_observation
  }
  getAEnvoyerALaBD() {
    return this.AEnvoyerALaBD
  }
  getDA() {
    return this.DA
  }
  getDerDA() {
    return this.DA[this.DA.length - 1]
  }
}

export class PneuHistorique {
  private sph_id: string
  private sp_id: string
  private sm_id: number
  private sp_monteOu: string
  private sp_etat: string
  private sp_action: string
  private sph_date: string
  private sph_commentaire: string

  constructor()
  constructor(unHistorique: PneuHistorique)
  constructor(sph_id: string, sp_id: string, sm_id: number, sp_monteOu: string, sp_etat: string, sp_action: string, sph_date: string, sph_commentaire: string)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.sph_id = args[0].sph_id
      this.sp_id = args[0].sp_id
      this.sm_id = args[0].sm_id
      this.sp_monteOu = args[0].sp_monteOu
      this.sp_etat = args[0].sp_etat
      this.sp_action = args[0].sp_action
      this.sph_date = args[0].sph_date
      this.sph_commentaire = args[0].sph_commentaire
    } else if (args.length == 8) {
      this.sph_id = args[0]
      this.sp_id = args[1]
      this.sm_id = args[2]
      this.sp_monteOu = args[3]
      this.sp_etat = args[4]
      this.sp_action = args[5]
      this.sph_date = args[6]
      this.sph_commentaire = args[7]
    } else {
      this.sph_id = ""
      this.sp_id = ""
      this.sm_id = -1
      this.sp_monteOu = ""
      this.sp_etat = ""
      this.sp_action = ""
      this.sph_date = ""
      this.sph_commentaire = ""
    }
  }
  getId() {
    return this.sph_id
  }
  getIdPneu() {
    return this.sp_id
  }
  getIdMachine() {
    return this.sm_id
  }
  getMonteOu() {
    return this.sp_monteOu
  }
  getEtat() {
    return this.sp_etat
  }
  getAction() {
    return this.sp_action
  }
  getDate() {
    return this.sph_date
  }
  getCommentaire() {
    return this.sph_commentaire
  }

  setId(sph_id: string) {
    this.sph_id = sph_id
  }
  setIdPneu(sp_id: string) {
    this.sp_id = sp_id
  }
  setIdMachine(sm_id: number) {
    this.sm_id = sm_id
  }
  setMonteOu(sp_monteOu: string) {
    this.sp_monteOu = sp_monteOu
  }
  setEtat(sp_etat: string) {
    this.sp_etat = sp_etat
  }
  setAction(sp_action: string) {
    this.sp_action = sp_action
  }
  setDate(sph_date: string) {
    this.sph_date = sph_date
  }
  setCommentaire(sph_commentaire: string) {
    this.sph_commentaire = sph_commentaire
  }
}

export class Pneu {
  private sp_id: string
  private sp_code: string
  private sp_dimension: string
  private sp_etat: string
  private sm_id: number
  private sp_monteOu: string
  private sp_historique: PneuHistorique[]

  constructor()
  constructor(unPneu: Pneu)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.sp_id = args[0].sp_id
      this.sp_code = args[0].sp_code
      this.sp_dimension = args[0].sp_dimension
      this.sp_etat = args[0].sp_etat
      this.sm_id = args[0].sm_id
      this.sp_monteOu = args[0].sp_monteOu
      if (args[0].sp_historique != null) {
        this.sp_historique = args[0].sp_historique.map((h: PneuHistorique) => {
          return new PneuHistorique(h)
        })
      } else {
        this.sp_historique = []
      }
    } else {
      this.sp_id = ""
      this.sp_code = ""
      this.sp_dimension = ""
      this.sp_etat = ""
      this.sm_id = 0
      this.sp_monteOu = ""
      this.sp_historique = []
    }
  }

  getId() {
    return this.sp_id
  }
  getCode() {
    return this.sp_code
  }
  getDimension() {
    return this.sp_dimension
  }
  getEtat() {
    return this.sp_etat
  }
  getIdMachine() {
    return this.sm_id
  }
  getMonteOu() {
    return this.sp_monteOu
  }
  getHistorique() {
    return this.sp_historique
  }

  setId(sp_id: string) {
    this.sp_id = sp_id
  }
  setCode(sp_code: string) {
    this.sp_code = sp_code
  }
  setDimension(sp_dimension: string) {
    this.sp_dimension = sp_dimension
  }
  setEtat(sp_etat: string) {
    this.sp_etat = sp_etat
  }
  setIdMachine(sm_id: number) {
    this.sm_id = sm_id
  }
  setMonteOu(sp_monteOu: string) {
    this.sp_monteOu = sp_monteOu
  }
  setHistorique(sp_historique: PneuHistorique[]) {
    this.sp_historique = sp_historique
  }
  pushHistorique(sp_historique: PneuHistorique) {
    this.sp_historique.push(sp_historique)
  }
  peutEtreMonter() {
    if (this.sp_etat == "H" || this.sp_etat == "D") {
      return false
    }
    return true
  }
}

export class Machine {
  private sm_id: number
  private sm_plaque: string
  private sm_categorie: string
  private sh_valeur: string
  private sm_modele: string
  private sm_serie: string
  private sm_designation: string
  private sm_dateEntree: string
  private sm_dateSortie: string
  private sm_observation: string
  private sm_status: string
  private sm_poussiere: boolean
  private pneus: Pneu[]
  private sg_code1: string
  private sg_code2: string

  constructor()
  constructor(uneMachine: Machine)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.sm_id = args[0].sm_id
      this.sm_plaque = args[0].sm_plaque
      this.sm_categorie = args[0].sm_categorie
      this.sh_valeur = args[0].sh_valeur
      this.sm_modele = args[0].sm_modele
      this.sm_serie = args[0].sm_serie
      this.sm_designation = args[0].sm_designation
      this.sm_dateEntree = args[0].sm_dateEntree
      this.sm_dateSortie = args[0].sm_dateSortie
      this.sm_observation = args[0].sm_observation
      this.sm_poussiere = args[0].sm_poussiere == "o"
      this.sm_status = args[0].sm_status
      if (args[0].pneus !== undefined && args[0].pneus.length != 0) {
        this.pneus = args[0].pneus.map((p: Pneu) => {
          return new Pneu(p)
        })
      } else {
        this.pneus = []
      }
      this.sg_code1 = args[0].sg_code1
      this.sg_code2 = args[0].sg_code2
    } else {
      this.sm_id = -1
      this.sm_plaque = ""
      this.sm_categorie = ""
      this.sh_valeur = ""
      this.sm_modele = ""
      this.sm_serie = ""
      this.sm_designation = ""
      this.sm_dateEntree = ""
      this.sm_dateSortie = ""
      this.sm_observation = ""
      this.sm_poussiere = false
      this.pneus = []
      this.sm_status = ""
      this.sg_code1 = ""
      this.sg_code2 = ""
    }
  }

  getId() {
    return this.sm_id
  }
  getPlaque() {
    return this.sm_plaque
  }
  getCategorie() {
    return this.sm_categorie
  }
  getHorametre() {
    return this.sh_valeur
  }
  getModele() {
    return this.sm_modele
  }
  getSerie() {
    return this.sm_serie
  }
  getDesignation() {
    return this.sm_designation
  }
  getDateEntree() {
    return this.sm_dateEntree
  }
  getDateSortie() {
    return this.sm_dateSortie
  }
  getObservation() {
    return this.sm_observation
  }
  getPoussiere() {
    return this.sm_poussiere
  }
  getStatus() {
    return this.sm_status
  }
  getPneu() {
    return this.pneus
  }
  getCode1() {
    return this.sg_code1
  }
  getCode2() {
    return this.sg_code2
  }
  setId(sm_id: number) {
    this.sm_id = sm_id
  }
  setDateEntree(sm_dateEntree: string) {
    this.sm_dateEntree = sm_dateEntree
  }
  setDateSortie(sm_dateSortie: string) {
    this.sm_dateSortie = sm_dateSortie
  }
  setPneu(lesPneu: Pneu[]) {
    this.pneus = lesPneu
  }
  pushPneu(unPneu: Pneu) {
    this.pneus.push(unPneu)
  }
}

export class Utilisateur {
  ut_id: number
  ut_matricule: string | null
  ut_nom: string | null
  ut_prenom: string | null
  ut_login: string | null
  ut_email: string | null
  ut_pass: string | null
  ut_pass_num: string | null
  ut_service: string | null
  ut_langue: string | null
  ut_societe: string | null
  ut_date_entree: Date | string | null
  ut_date_sortie: Date | string | null
  ut_visite_medicale: string | null
  ut_caces: string | null
  ut_num_badge: string | null

  constructor()
  constructor(unUtilisateur: Utilisateur)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.ut_id = args[0].ut_id
      this.ut_matricule = args[0].ut_matricule
      this.ut_nom = args[0].ut_nom
      this.ut_prenom = args[0].ut_prenom
      this.ut_login = args[0].ut_login
      this.ut_email = args[0].ut_email
      this.ut_pass = args[0].ut_pass
      this.ut_pass_num = args[0].ut_pass_num
      this.ut_service = args[0].ut_service
      this.ut_langue = args[0].ut_langue
      this.ut_societe = args[0].ut_societe
      this.ut_date_entree = args[0].ut_date_entree
      this.ut_date_sortie = args[0].ut_date_sortie
      this.ut_visite_medicale = args[0].ut_visite_medicale
      this.ut_caces = args[0].ut_caces
      this.ut_num_badge = args[0].ut_num_badge
    } else {
      this.ut_id = -1
      this.ut_matricule = ""
      this.ut_nom = ""
      this.ut_prenom = ""
      this.ut_login = ""
      this.ut_email = ""
      this.ut_pass = ""
      this.ut_pass_num = ""
      this.ut_service = ""
      this.ut_langue = ""
      this.ut_societe = ""
      this.ut_date_entree = ""
      this.ut_date_sortie = ""
      this.ut_visite_medicale = ""
      this.ut_caces = ""
      this.ut_num_badge = ""
    }
  }
}

export class Autorisation {
  private sa_designation: string
  private affichage: string
  private sa_autorise: any[]

  constructor()
  constructor(uneAutorisation: Autorisation)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.sa_designation = args[0].sa_designation
      this.affichage = ""
      this.sa_autorise = args[0].sa_autorise
    } else {
      this.sa_designation = ""
      this.affichage = ""
      this.sa_autorise = []
    }
  }

  getDesignation() {
    return this.sa_designation
  }
  getAffichage() {
    return this.affichage
  }
  getAutorise() {
    return this.sa_autorise
  }
  setAutorise(lesAutorisations: any[]) {
    this.sa_autorise = lesAutorisations
  }
  setAffichage(chaine: string) {
    this.affichage = chaine
  }
}

export class Maintenance {
  private sma_id: string
  private ut_id: string
  private sma_date: string
  private sma_tempsPasse: number
  private sma_horametre: string
  private sma_autreMaintenance: string
  private sma_pneu: string

  constructor()
  constructor(uneMaintenance: Maintenance)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.sma_id = args[0].sma_id
      this.ut_id = args[0].ut_id
      this.sma_date = args[0].sma_date
      this.sma_tempsPasse = args[0].sma_tempsPasse
      this.sma_horametre = args[0].sma_horametre
      this.sma_autreMaintenance = args[0].sma_autreMaintenance
      this.sma_pneu = args[0].sma_pneu
    } else {
      this.sma_id = ""
      this.ut_id = ""
      this.sma_date = ""
      this.sma_tempsPasse = 0
      this.sma_horametre = ""
      this.sma_autreMaintenance = ""
      this.sma_pneu = ""
    }
  }

  getId() {
    return this.sma_id
  }
  getUtilisateur() {
    return this.ut_id
  }
  getDate() {
    return this.sma_date
  }
  getTempsPasse() {
    return this.sma_tempsPasse
  }
  getHorametre() {
    return this.sma_horametre
  }
  getAutreMaintenance() {
    return this.sma_autreMaintenance
  }
  getPneu() {
    return this.sma_pneu
  }

  setId(sma_id: string) {
    this.sma_id = sma_id
  }
  setUtilisateur(ut_id: string) {
    this.ut_id = ut_id
  }
  setDate(sma_date: string) {
    this.sma_date = sma_date
  }
  setTempsPasse(sma_tempsPasse: number) {
    this.sma_tempsPasse = sma_tempsPasse
  }
  setHorametre(sma_horametre: string) {
    this.sma_horametre = sma_horametre
  }
  setAutreMaintenance(sma_autreMaintenance: string) {
    this.sma_autreMaintenance = sma_autreMaintenance
  }
  setPneu(sma_pneu: string) {
    this.sma_pneu = sma_pneu
  }
}

export class DemandeMaintenance {
  private sdm_id: string
  private sdm_date: string
  private sdm_dateCloture: string
  private sdm_machine: number
  private sdm_panne: string
  private sdm_etatDemande: string
  private sdm_prioriter: string
  private maintenance: Maintenance
  private ut_id: string

  constructor()
  constructor(uneDemande: DemandeMaintenance)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.sdm_id = args[0].sdm_id
      this.sdm_date = args[0].sdm_date
      this.sdm_dateCloture = args[0].sdm_dateCloture
      this.sdm_machine = args[0].sdm_machine
      this.sdm_panne = args[0].sdm_panne
      this.sdm_etatDemande = args[0].sdm_etatDemande
      this.sdm_prioriter = args[0].sdm_prioriter
      if (args[0].maintenance != null && args[0].maintenance !== undefined) {
        this.maintenance = new Maintenance(args[0].maintenance)
      } else {
        this.maintenance = new Maintenance()
      }
      this.ut_id = args[0].ut_id
    } else {
      this.sdm_id = ""
      this.sdm_date = ""
      this.sdm_dateCloture = ""
      this.sdm_machine = -1
      this.sdm_panne = ""
      this.sdm_etatDemande = ""
      this.sdm_prioriter = ""
      this.maintenance = new Maintenance()
      this.ut_id = ""
    }
  }

  getId() {
    return this.sdm_id
  }
  getDate() {
    return this.sdm_date
  }
  getDateDeCloture() {
    return this.sdm_dateCloture
  }
  getIDMachine() {
    return this.sdm_machine
  }
  getPanne() {
    return this.sdm_panne
  }
  getEtat() {
    return this.sdm_etatDemande
  }
  getPriorite() {
    return this.sdm_prioriter
  }
  getMaintenances() {
    return this.maintenance
  }
  getUtilisateur() {
    return this.ut_id
  }

  setId(sdm_id: string) {
    this.sdm_id = sdm_id
  }
  setDate(sdm_date: string) {
    this.sdm_date = sdm_date
  }
  setIDMachine(sdm_machine: number) {
    this.sdm_machine = sdm_machine
  }
  setPanne(sdm_panne: string) {
    this.sdm_panne = sdm_panne
  }
  setEtat(sdm_etatDemande: string) {
    this.sdm_etatDemande = sdm_etatDemande
  }
  setPriorite(sdm_prioriter: string) {
    this.sdm_prioriter = sdm_prioriter
  }
  setMaintenances(maintenance: Maintenance) {
    this.maintenance = maintenance
  }
  setUtilisateur(ut_id: string) {
    this.ut_id = ut_id
  }

  iniMaintenance() {
    this.setDate(sqlDate(new Date()))
    this.getMaintenances().setDate(sqlDate(new Date()))
    this.setPriorite("F")
    this.setEtat("D")
    this.getMaintenances().setTempsPasse(0)
  }
}

export class ChangementPneu {
  private emplamcementPneu: string
  private numPneuDemonter: string
  private etatPneuDemonter: string
  private sp_idDemonter: string
  private numPneuMonter: string
  private etatPneuMonter: string
  private sp_idMonter: string

  constructor()
  constructor(unChangement: ChangementPneu)
  constructor(emplacementPneu: string, numPneuDemonter: string, etatPneuDemonter: string, sp_idDemonter: string, numPneuMonter: string, etatPneuMonter: string, sp_idMonter: string)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.emplamcementPneu = args[0].emplamcementPneu
      this.numPneuDemonter = args[0].numPneuDemonter
      this.etatPneuDemonter = args[0].etatPneuDemonter
      this.sp_idDemonter = args[0].sp_idDemonter
      this.numPneuMonter = args[0].numPneuMonter
      this.etatPneuMonter = args[0].etatPneuMonter
      this.sp_idMonter = args[0].sp_idMonter
    } else if (args.length == 7) {
      this.emplamcementPneu = args[0]
      this.numPneuDemonter = args[1]
      this.etatPneuDemonter = args[2]
      this.sp_idDemonter = args[3]
      this.numPneuMonter = args[4]
      this.etatPneuMonter = args[5]
      this.sp_idMonter = args[6]
    } else {
      this.emplamcementPneu = ""
      this.numPneuDemonter = ""
      this.etatPneuDemonter = ""
      this.sp_idDemonter = ""
      this.numPneuMonter = ""
      this.etatPneuMonter = ""
      this.sp_idMonter = ""
    }
  }

  getEmplacementPneu() {
    return this.emplamcementPneu
  }
  getNumPneuDemonter() {
    return this.numPneuDemonter
  }
  getEtatPneuDemonter() {
    return this.etatPneuDemonter
  }
  getIdPneuDemonter() {
    return this.sp_idDemonter
  }
  getNumPneuMonter() {
    return this.numPneuMonter
  }
  getEtatPneuMonter() {
    return this.etatPneuMonter
  }
  getIdPneuMonter() {
    return this.sp_idMonter
  }
}

export class Zone {
  private sz_code: string
  private sz_nomZone: string
  private sz_situation: string
  private sz_observation: string
  private sg_code: string
  private sl_code: string
  modifier: boolean

  constructor(zone: Zone)
  constructor()
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.sz_code = args[0].sz_code
      this.sz_nomZone = args[0].sz_nomZone
      this.sg_code = args[0].sg_code
      this.sz_observation = args[0].sz_observation
      const split = args[0].sz_situation.split("/")
      if (split[0] == "Remplissage") {
        this.sz_situation = split[0]
        this.sl_code = split[1]
      } else {
        this.sz_situation = args[0].sz_situation
        this.sl_code = ""
      }
    } else {
      this.sz_code = ""
      this.sz_nomZone = ""
      this.sz_situation = ""
      this.sz_observation = ""
      this.sg_code = ""
      this.sl_code = ""
    }
    this.modifier = false
  }

  getCode() {
    return this.sz_code
  }
  getNomZone() {
    if (store.state.langue == "FR") {
      return this.sz_nomZone
    } else {
      const split = this.sz_nomZone.split(" ")
      return "Grube " + split[1]
    }
  }
  getSituation() {
    return this.sz_situation
  }
  getGroupeZone() {
    return this.sg_code
  }
  getLaitier() {
    return this.sl_code
  }
  getObservation() {
    return this.sz_observation
  }
  setLaitier(unLaitier: string) {
    this.sl_code = unLaitier
  }
}

export class ChecklisteControle {
  private code: string
  private libelle: string
  private libelle_AL: string
  private libelle_FR: string
  private observation: string
  private reponse: boolean | null

  constructor()
  constructor(unControle: ChecklisteControle)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.code = args[0].code
      this.libelle = args[0].libelle
      this.libelle_AL = args[0].libelle_AL
      this.libelle_FR = args[0].libelle_FR
      this.observation = args[0].observation
      this.reponse = args[0].reponse
    } else {
      this.code = ""
      this.libelle = ""
      this.libelle_AL = ""
      this.libelle_FR = ""
      this.observation = ""
      this.reponse = null
    }
  }

  getLibelleLangue(lang: string) {
    if (lang == "FR") {
      return this.libelle_FR
    }
    return this.libelle_AL
  }
  getReponse() {
    return this.reponse
  }
  setLibelle(leLibelle: string) {
    this.libelle = leLibelle
  }
}

export class Checkliste {
  private controles: ChecklisteControle[]
  private texte: string
  private texte_AL: string
  private texte_FR: string

  constructor()
  constructor(uneCheckListe: Checkliste)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.controles = args[0].controles.map((c: ChecklisteControle) => {
        return new ChecklisteControle(c)
      })
      this.texte = args[0].texte
      this.texte_AL = args[0].texte_AL
      this.texte_FR = args[0].texte_FR
    } else {
      this.controles = []
      this.texte = ""
      this.texte_AL = ""
      this.texte_FR = ""
    }
  }

  getControles() {
    return this.controles
  }
  getTexteLangue(lang: string) {
    if (lang == "FR") {
      return this.texte_FR
    }
    return this.texte_AL
  }
  setTexte(leTexte: string) {
    this.texte = leTexte
  }
}

export class CheckListeCategorie {
  private engin: string
  private checklistes: Checkliste[]

  constructor()
  constructor(uneCategorie: CheckListeCategorie)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.engin = args[0].engin
      this.checklistes = args[0].checklistes.map((c: Checkliste) => {
        return new Checkliste(c)
      })
    } else {
      this.engin = ""
      this.checklistes = []
    }
  }

  getEngin() {
    return this.engin
  }
  getCheckListes() {
    return this.checklistes
  }
}

export class ChecklisteBD {
  private cl_id: string
  private sp_id: string
  private ut_id: number
  private sm_id: number
  private cl_donnee: CheckListeCategorie

  constructor()
  constructor(uneCheckliste: ChecklisteBD)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.cl_id = args[0].cl_id
      this.sp_id = args[0].sp_id
      this.ut_id = args[0].ut_id
      this.sm_id = args[0].sm_id
      this.cl_donnee = new CheckListeCategorie(args[0].cl_donnee)
    } else {
      this.cl_id = ""
      this.sp_id = ""
      this.ut_id = -1
      this.sm_id = -1
      this.cl_donnee = new CheckListeCategorie()
    }
  }

  getId() {
    return this.cl_id
  }
  getIdPoste() {
    return this.sp_id
  }
  getIdUtilisateur() {
    return this.ut_id
  }
  getIdMachine() {
    return this.sm_id
  }
  getCheckiliste() {
    return this.cl_donnee
  }
}

export class Poste {
  private sp_id: string
  private ut_id: string
  private sp_datetimeDebut: string
  private sp_datetimeFin: string
  private sp_poste: string
  private sp_observation: string
  private checklistes: ChecklisteBD[]
  afficherCheckListe: boolean

  constructor()
  constructor(unPoste: Poste)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.sp_id = args[0].sp_id
      this.ut_id = args[0].ut_id
      this.sp_datetimeDebut = args[0].sp_datetimeDebut
      this.sp_datetimeFin = args[0].sp_datetimeFin
      this.sp_poste = args[0].sp_poste
      this.sp_observation = args[0].sp_observation
      if (args[0].checklistes !== undefined) {
        this.checklistes = args[0].checklistes.map((c: ChecklisteBD) => {
          return new ChecklisteBD(c)
        })
      } else {
        this.checklistes = []
      }
    } else {
      this.sp_id = ""
      this.ut_id = ""
      this.sp_datetimeDebut = ""
      this.sp_datetimeFin = ""
      this.sp_poste = ""
      this.sp_observation = ""
      this.checklistes = []
    }
    this.afficherCheckListe = false
  }

  getId() {
    return this.sp_id
  }
  getIdUtilisateur() {
    return this.ut_id
  }
  getDebutPoste() {
    return this.sp_datetimeDebut
  }
  getFinPoste() {
    return this.sp_datetimeFin
  }
  getTypePoste() {
    return this.sp_poste
  }
  getTypePosteAffichage() {
    if (store.state.langue == "FR") {
      switch (this.sp_poste) {
        case "m":
          return "Matin"
        case "p":
          return "Après-midi"
        case "n":
          return "Nuit"
      }
    } else {
      switch (this.sp_poste) {
        case "m":
          return "Morgen"
        case "p":
          return "Nachmittag"
        case "n":
          return "Nacht"
      }
    }
  }
  getObservation() {
    return this.sp_observation
  }
  getCheckliste() {
    return this.checklistes
  }
  getControle() {
    let tousLesControles: ChecklisteControle[] = []
    for (const checklistes of this.checklistes) {
      const lesChecklistes = checklistes.getCheckiliste().getCheckListes()
      for (const uneCheckliste of lesChecklistes) {
        for (const unControle of uneCheckliste.getControles()) {
          tousLesControles.push(unControle)
        }
      }
    }
    return tousLesControles
  }
  setAfficher(afficher: boolean) {
    this.afficherCheckListe = afficher
  }
}

export class TypeMachine {
  private tm_id: number
  private tm_nomFR: string
  private tm_nomAL: string

  constructor()
  constructor(unTypeMachine: TypeMachine)
  constructor(...args: any[]) {
    if (args.length == 1) {
      this.tm_id = args[0].tm_id
      this.tm_nomFR = args[0].tm_nomFR
      this.tm_nomAL = args[0].tm_nomAL
    } else {
      this.tm_id = -1
      this.tm_nomFR = ""
      this.tm_nomAL = ""
    }
  }

  getId() {
    return this.tm_id
  }
  getType(lang: string) {
    if (lang == "FR") {
      return this.tm_nomFR
    }
    return this.tm_nomAL
  }
}
